<script>
import clickOutside from "@/directives/clickOutside";
import trapFocus from "@/directives/trapFocus";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },

  directives: {
    trapFocus,
    clickOutside,
    escape: {
      bind: function(el, binding, vnode) {
        el.escapeEvent = function(event) {
          if (event.which == 27) vnode.context[binding.expression](event);
        };
        document.addEventListener("keyup", el.escapeEvent);
      },
      unbind: function(el) {
        document.removeEventListener("keyup", el.escapeEvent);
      },
    },
  },

  computed: {
    ...mapGetters("session", ["loggedIn", "accUserInfo"]),
  },

  methods: {
    exit() {
      this.$emit("input", false);
    },
  },

  watch: {
    $route() {
      this.exit();
    },
  },
};
</script>

<template>
  <div
    class="accountPreview"
    :class="{ active: value }"
    v-click-outside="exit"
    v-escape="exit"
    v-trap-focus
  >
    <div class="preview__section chip">
      <div class="chip__avatar">
        <div class="avatar">
          <img
            v-if="accUserInfo.user_avatar"
            :src="accUserInfo.user_avatar.src"
            :srcset="accUserInfo.user_avatar.srcset"
          />
          <img v-else src="@/assets/avatar-square.svg" />
        </div>
      </div>
      <div class="chip__content">
        <div class="nickname">{{ accUserInfo.user_name_full }}</div>
        <div class="email">{{ accUserInfo.user_email }}</div>
        <div class="credits">
          <span class="da__label">Balance:</span>
          <span class="da__value"
            >${{ accUserInfo.user_credits | formatMoney(0) }}
            {{
              accUserInfo.user_credits > 1 || !accUserInfo.user_credits
                ? "créditos"
                : "crédito"
            }}</span
          >
        </div>
      </div>
    </div>
    <div class="preview__section preview_nav">
      <router-link :to="{ name: 'acc-watching' }">Guardados</router-link>
      <router-link :to="{ name: 'acc-inbox' }">Mensajes</router-link>
      <router-link :to="{ name: 'acc-listings' }">Publica</router-link>
      <router-link :to="{ name: 'acc-org' }">Organización</router-link>
    </div>
    <div class="preview__section">
      <button
        tag="button"
        class="button is-small"
        @click="$store.dispatch('session/validateAuthToken')"
      >
        Validar token
      </button>
      <button
        tag="button"
        class="button is-small"
        @click="$store.dispatch('session/renewAuthToken')"
      >
        Renovar token
      </button>
      <router-link :to="{ name: 'logout' }" custom v-slot="{ navigate }">
        <button
          class="button is-small"
          @click="navigate"
          @keypress.enter="navigate"
          role="link"
        >
          Cerrar sesión
        </button>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.accountPreview {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease, visibility 0s linear 0.15s;
  background-color: #fff;
  // padding: 1rem;
  outline: none;
  &.active {
    opacity: 1;
    visibility: visible;
    transition: transform 0.15s ease;
  }
  .preview__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.25rem var(--sitio-ui-gap-size);
    &:not(:first-child) {
      border-top: 1px solid #eee;
    }
  }
  .chip {
    // margin: var(--sitio-ui-gap-size);
    text-align: center;
    .avatar {
      width: 5.5rem;
      height: 5.5rem;
      margin-bottom: 1rem;
      border-radius: 100%;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
      }
    }
    .nickname {
      font-weight: bold;
      color: #000;
    }
    .email {
      font-size: 0.875em;
      font-weight: 500;
      opacity: 0.85;
    }
    .credits {
      display: inline-block;
      margin-top: 0.5rem;
      background-color: rgb(246, 246, 246);
      padding: 0.375rem 1rem;
      color: #000;
      border-radius: 2rem;
      font-size: 0.875rem;
      .da__label {
        font-weight: bold;
        opacity: 0.5;
        margin-right: 0.25rem;
      }
    }
  }
  .preview_nav {
    padding: 0.75rem 1rem;
    > * {
      // flex: 1 1 100%;
      width: 100%;
      text-align: center;
      padding: 0.375rem 0;
      // text-transform: uppercase;
      letter-spacing: 1px;
      // opacity: 0.7;
      color: #003265;
    }
    > *:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
}
</style>
